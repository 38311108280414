import React from "react"
import { Link, graphql } from "gatsby"
import Navigation from "../components/navigation"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Objetivo from "../images/SVG/objetivo.svg"
import Dream from "../images/iconos-08.png"
import Footer from "../components/footer"

const IndexPage = props => (
  <Layout>
    <SEO title="Sobre Sueña en Colores" />
    <Navigation />

    <div className="w-full mx-auto top-illustration">
      <Img fluid={props.data.oneImage.childImageSharp.fluid} className="h-64" />
    </div>

    <div className="w-full mx-auto text-center bg-blue-200 pt-8 pb-8">
      <h1 className="text-3xl lg:text-4xl text-blue-900">Nuestros Servicios</h1>
      <div>
        <p className="w-10/12 lg:w-1/2 text-lg mt-3 mx-auto">
          Trabajamos con población infantil y adolescente en riesgo social y
          contexto complejo.
        </p>
      </div>
    </div>

    <div className="w-full mx-auto bg-green-100 pt-8 pb-8">
      <div className="content-item w-full lg:w-10/12 lg:flex mx-auto">
        <div className="w-full lg:w-1/4 text-center">
          <img
            src={Objetivo}
            alt="Mindfulness y Arteterapa"
            className="mx-auto w-1/2 lg:w-8/12"
          />
        </div>
        <div className="w-full lg:w-3/4">
          <h2 className="text-2xl my-3  px-5 text-blue-800">
            Aulas de colores
          </h2>
          <p className="px-5">
            Un programa grual para implementar a lo largo de 1 año con sesiones
            semanales que buscan potenciar expresión creativa, conocimiento de
            sí mismo y lazo social positivo a través del Mindfulness y el
            Arteterapia.
          </p>

          <h2 className="text-2xl my-3 lg:mt-10 px-5 text-blue-800">
            Capacitación de Mindfulness para Docentes
          </h2>
          <p className="px-5">
            Un programa desarrollado especialmente para docentes y personal de
            escuelas. Se les capacita en la práctica y la enseñanza de
            Mindfulness para poder practicarlo con los alumnos.
          </p>

          <h2 className="text-2xl my-3 lg:mt-10 px-5 text-blue-800">
            Capacitación de Mindfulness para Padres
          </h2>
          <p className="px-5">
            Un programa desarrollado especialmente para que padres de familia
            puedan practicar Mindfulness de forma personal y también enseñarlo a
            su familia.
          </p>

          <h2 className="text-2xl my-3 lg:mt-10 px-5 text-blue-800">
            Capacitación de Liderazgo conciente para empresas
          </h2>
          <p className="px-5">
            Un programa enfocado en el desarrollo de capacidades de liderazgo
            basado en principios de Mindfulness, compasión y empatía. Se busca
            desarrollar nuevas habilidades en los líderes de empresas para poder
            crear equipos de trabajo más felices, productivos y resilientes.
          </p>

          <h2 className="text-2xl my-3 lg:mt-10 px-5 text-blue-800">
            Sesiones privadas de Mindfulness o Arteterapia grupales
          </h2>
          <p className="px-5">
            Nosotros diseñamos sesiones basadas en las necesidades particulares
            del grupo. Tenemos especialistas tanto en Arteterapia como en
            Mindfulness y podemos trabajar en crear un programa específico que
            fortalezca las carencias que cada grupo quiera mejorar.
          </p>

          <h2 className="text-2xl my-3 lg:mt-10 px-5 text-blue-800">
            Campaña Adopta un Chancho
          </h2>
          <p className="px-5">
            Decenas de niños de los programas de Sueña en Colores han pintado
            chanchitos de arcilla que puedes comprar para llevar a tu casa o
            lugar de trabajo. Una vez que llenas su chanchito, lo devuelves a
            Sueña en Colores como una donación para la Asociación.
          </p>
        </div>
      </div>
    </div>

    <div className="w-full mx-auto text-center bg-gray-100 pt-8 pb-8 border border-gray-200">
      <h1 className="text-2xl lg:text-4xl text-blue-900">
        Uno hace la diferencia
      </h1>
      <div className="mt-5">
        <p className="w-2/3 lg:w-1/2 text-md mt-3 mx-auto">
          La ciencia ha demostrado algo que todos intuimos. Ayudar a otros nos
          hace personas más felices. Ahora sigue el paso más importante: hacelo
          hoy, ayudá hoy.
        </p>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default IndexPage

export const anaQuery = graphql`
  query {
    oneImage: file(relativePath: { eq: "servicios.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
